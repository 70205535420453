import * as React from 'react'
import i18n from 'i18n'
import { NppDatePicker } from '@ui/Components'
import { protectionCoverageReportPath, seatUsageReportPath } from '@config/Routes/WebClientRoutes'
import WidgetCardBlock from 'bundles/ui/Blocks/DashboardPageBlocks/WidgetCardBlock'
import { TypeChart } from '@pages/ReportingDashboardPage/pageHooks/useFetchReportingDashboardWidget'

const WidgetCardSection = ({
  isLoadingProtectionCoverage,
  protectionCoverageData,
  isLoadingSeatUsage,
  seatUsageData,
  isLoadingOrganizations,
  organizationsData,
  isLoadingAccounts,
  accountsData,
  isMasterPartner,
  handleDateSelected,
  chartDataProtection,
  chartDataSeatUsage,
  chartDataOrganizations,
  chartDataAccounts
}) => {
  const { t } = i18n

  return (
    <div className="ReportingDashboard__content-wrapper">
      <div className="ReportingDashboard__card-wrapper">
        <WidgetCardBlock
          title={t('reporting_dashboard.index.protection_coverage_card.header')}
          description={t('reporting_dashboard.index.protection_coverage_card.sub_header', {
            date: protectionCoverageData?.updated_at ?? ''
          })}
          tooltipTitle={t('reporting_dashboard.index.protection_coverage_card.info_header')}
          button={t('reporting_dashboard.index.protection_coverage_card.report_detail_button')}
          actionButton={protectionCoverageReportPath}
          isLoading={isLoadingProtectionCoverage}
          cardInfo={t(
            `reporting_dashboard.index.protection_coverage_card.description${
              !isMasterPartner ? '_without_partner' : ''
            }_html`,
            {
              organizations: protectionCoverageData?.organizations_count ?? 0,
              partners: protectionCoverageData?.resellers_count ?? 0
            }
          )}
          chartData={chartDataProtection}
          chartType="gauge"
          chartTitle={t('reporting_dashboard.index.protection_coverage_card.chart_label')}
          showValueChart
          chartInfo={t('reporting_dashboard.index.protection_coverage_card.chart_description_html', {
            accounts_backed_up: protectionCoverageData?.protected_account_count ?? 0,
            total_accounts: protectionCoverageData?.total_account_count ?? 0
          })}
          sideInfoTitle={protectionCoverageData?.unprotected_account_count ?? 0}
          sideInfoDescription={t('reporting_dashboard.index.protection_coverage_card.description_2')}
        />
        <WidgetCardBlock
          title={t('reporting_dashboard.index.seat_usage_card.header')}
          description={t('reporting_dashboard.index.seat_usage_card.sub_header', {
            date: seatUsageData?.updated_at ?? ''
          })}
          tooltipTitle={t('reporting_dashboard.index.seat_usage_card.info_header')}
          button={t('reporting_dashboard.index.seat_usage_card.report_detail_button')}
          actionButton={seatUsageReportPath}
          isLoading={isLoadingSeatUsage}
          cardInfo={t(
            `reporting_dashboard.index.seat_usage_card.description${!isMasterPartner ? '_without_partner' : ''}_html`,
            {
              organizations: seatUsageData?.organizations_count ?? 0,
              partners: seatUsageData?.resellers_count ?? 0
            }
          )}
          chartData={chartDataSeatUsage}
          chartType="gauge"
          chartTitle={t('reporting_dashboard.index.seat_usage_card.chart_label')}
          showValueChart
          chartInfo={t('reporting_dashboard.index.seat_usage_card.chart_description_html', {
            paid_seat: seatUsageData?.used_seat ?? 0,
            total_paid: seatUsageData?.paid_seat ?? 0
          })}
        />
      </div>
      <div className="ReportingDashboard__card-wrapper">
        <WidgetCardBlock
          title={t('reporting_dashboard.index.organization_card.header')}
          description={'20 Feb 2024 at 10:49'}
          button={t('reporting_dashboard.index.organization_card.report_detail_button')}
          actionButton="/organizations_dashboard"
          typeButton="href"
          isLoading={isLoadingOrganizations || Object.keys(organizationsData).length === 0}
          rightHeader={<NppDatePicker onDateSelected={(startDate, endDate) => handleDateSelected(startDate, endDate, TypeChart.organizations)} />}
          chartData={chartDataOrganizations}
          chartType="multiple"
          showValueChart
        />
        <WidgetCardBlock
          title={t('reporting_dashboard.index.account_card.header')}
          description={'20 Feb 2024 at 10:49'}
          button={t('reporting_dashboard.index.account_card.report_detail_button')}
          actionButton="/accounts_dashboard"
          typeButton="href"
          isLoading={isLoadingAccounts || Object.keys(accountsData).length === 0}
          rightHeader={<NppDatePicker onDateSelected={(startDate, endDate) => handleDateSelected(startDate, endDate, TypeChart.accounts)} />}
          chartData={chartDataAccounts}
          chartType="stackedBar"
          showValueChart
        />
      </div>
    </div>
  )
}

export default WidgetCardSection
