import * as React from 'react'
import i18n from 'i18n'
import useFetchReportingDashboardWidget from './pageHooks/useFetchReportingDashboardWidget'
import usePartnerDropdown from '@pages/sharedHooks/usePartnerDropdown'
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock'
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection'
import AlertHtmlPageSection from '@ui/Sections/Shared/AlertHtmlPageSection'
import BannerHtmlPageSection from '@ui/Sections/Shared/BannerHtmlPageSection'
import PartnerHeaderSection from '@ui/Sections/Shared/PartnerHeaderSection'
import BillingCardSection from '@ui/Sections/DashboardPage/BillingCardSection'
import WidgetCardSection from '@ui/Sections/DashboardPage/WidgetCardSection'

export default function ReportingDashboardPage() {
  const { t } = i18n

  const {
    selected,
    setSelected,
    searchValue,
    setSearchValue,
    getTotalSelected,
    onFilterDropdown,
    onSelectItem,
    listPartnerSearched,
    listPartnerDefault,
    selectedIds,
    totalSelectedText,
    setSelectedPartnerSuccess,
    successListPartnerDropdown
  } = usePartnerDropdown()

  const {
    isLoading,
    isLoadingProtectionCoverage,
    protectionCoverageData,
    isLoadingSeatUsage,
    seatUsageData,
    isLoadingOrganizations,
    organizationsData,
    isLoadingAccounts,
    accountsData,
    isLoadingBilling,
    billingData,
    isMasterPartner,
    handleDateSelected,
    chartDataProtection,
    chartDataSeatUsage,
    chartDataOrganizations,
    chartDataAccounts
  } = useFetchReportingDashboardWidget({setSelectedPartnerSuccess, successListPartnerDropdown, partnerIds: selectedIds ?? [] })

  return (
    <div className="ReportingDashboard__wrapper">
      <HeaderPageBlock title={t('reporting_dashboard.index.title')} />
      <BannerHtmlPageSection />
      <AlertHtmlPageSection />
      <AlertPageSection />
      <PartnerHeaderSection
        isMasterPartner={isMasterPartner}
        isLoading={isLoading}
        selected={selected}
        setSelected={setSelected}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        getTotalSelected={getTotalSelected}
        onFilterDropdown={onFilterDropdown}
        onSelectItem={onSelectItem}
        listPartnerSearched={listPartnerSearched}
        listPartnerDefault={listPartnerDefault}
        totalSelectedText={totalSelectedText}
        showAddPartner
      />
      <BillingCardSection isMasterPartner={isMasterPartner} billingData={billingData} isLoading={isLoadingBilling}/>
      <WidgetCardSection
        isLoadingProtectionCoverage={isLoadingProtectionCoverage}
        protectionCoverageData={protectionCoverageData}
        isLoadingSeatUsage={isLoadingSeatUsage}
        seatUsageData={seatUsageData}
        isLoadingOrganizations={isLoadingOrganizations}
        organizationsData={organizationsData}
        isLoadingAccounts={isLoadingAccounts}
        accountsData={accountsData}
        isMasterPartner={isMasterPartner}
        handleDateSelected={handleDateSelected}
        chartDataProtection={chartDataProtection}
        chartDataSeatUsage={chartDataSeatUsage}
        chartDataOrganizations={chartDataOrganizations}
        chartDataAccounts={chartDataAccounts}
      />
    </div>
  )
}
