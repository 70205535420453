/**
 * Service client Library
 *
 * Service client Library uses Factory Design Pattern
 * the factory will be intialized within DateTimeFactory Class
 *
 * available client:
 *   - moment
 *
 * eg:
 *   # by defailt the type will be momentjs
 *   dateTimeClient = new DateTimeFactory().create();
 *
 *   # get the client object
 *   # will return moment js
 *   dateTimeClient.getClient()
 *
 * * */

import DateTimeFactory from './DateTimeFactory';

export default DateTimeFactory;
