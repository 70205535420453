import { createSlice } from '@reduxjs/toolkit'

import { getPartnerTypeAsync, getPartnerTypeExtraReducers } from './serviceActions/getPartnerTypeAysnc'

interface applicationState {
  partnerType: ''
  getPartnerType: boolean
  error: null | string
  alert: object
}

const initialState: applicationState = {
  partnerType: '',
  getPartnerType: true,
  error: null,
  alert: null
}

const listPartnerSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    resetPartnerType(state) {
      state.partnerType = initialState.partnerType
      state.getPartnerType = initialState.getPartnerType
      state.error = initialState.error
    },
    setAlert(state, action) {
      state.alert = {
        type: action.payload.type,
        message: action.payload.message,
        heading: action.payload.heading,
        path: action.payload.path,
        html: action.payload.html
      }
    },
    clearAlert(state) {
      state.alert = null
    }
  },
  extraReducers: (builder) => {
    getPartnerTypeExtraReducers(builder)
    // Add other extraReducers here if needed
  }
})

export { getPartnerTypeAsync }
export const { resetPartnerType, clearAlert, setAlert } = listPartnerSlice.actions
export default listPartnerSlice.reducer
