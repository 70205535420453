import * as React from 'react'
import { NppCard, NppTypography, NppChartC3 } from '@ui/Components'
import SanitizedHtml from '@ui/Blocks/Shared/SanitizedHtml'

interface WidgetCardBlockProps {
  typeButton?: 'navigate' | 'href'
  [key: string]: any;
}

const WidgetCardBlock = ({
  title,
  description,
  tooltipTitle = '',
  button,
  actionButton,
  typeButton = 'navigate',
  isLoading,
  rightHeader = null,
  cardInfo = '',
  chartData,
  chartType,
  chartTitle = '',
  showValueChart,
  chartInfo = '',
  sideInfoTitle = '',
  sideInfoDescription = ''
}: WidgetCardBlockProps) => {
  return (
    <NppCard
      title={title}
      description={description}
      tooltipTitle={tooltipTitle}
      button={button}
      actionButton={actionButton}
      typeButton={typeButton}
      loading={isLoading}
      alignButton="center"
      rightHeader={rightHeader}>
      {cardInfo ? (
        <NppTypography className="ReportingDashboard_card-info">
          <SanitizedHtml htmlContent={cardInfo} />
        </NppTypography>
      ) : null}

      <div className={`ReportingDashboardWidget_wrapper${sideInfoTitle ? '_side_info' : ''}`}>
        {chartInfo ? (
          <div className="ReportingDashboardWidget_col-chart-wrapper">
            <NppChartC3 data={chartData} type={chartType} showValue={showValueChart} title={chartTitle} />
            <NppTypography className="ReportingDashboardWidget_chart-info" wrap>
              <SanitizedHtml htmlContent={chartInfo} />
            </NppTypography>
          </div>
        ) : (
          <NppChartC3 data={chartData} type={chartType} showValue={showValueChart} title={chartTitle} />
        )}

        {sideInfoTitle ? (
          <div className="ReportingDashboardWidget_col-unprotected-wrapper">
            <i className="solid-padlock-shield" />
            <NppTypography wrap variant="h1">
              {sideInfoTitle}
            </NppTypography>
            <NppTypography className="ReportingDashboardWidget_chart-info" wrap>
              {sideInfoDescription}
            </NppTypography>
          </div>
        ) : null}
      </div>
    </NppCard>
  )
}

export default WidgetCardBlock
