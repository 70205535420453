import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/index'
import {
  getProtectionCoverageAsync,
  resetProtectionCoverage,
  getSeatUsageAsync,
  resetSeatUsage,
  getOrganizationsAsync,
  resetOrganizations,
  getAccountsAsync,
  resetAccounts,
  getBillingAsync,
  resetBilling
} from '@features/reportingDashboard/reportingDashboardSlice'
import { getPartnerTypeAsync, resetPartnerType } from '@features/application/applicationSlice'
import { numberToPercentage } from '@utilities/index'
import { usePageAlert, useHtmlPageAlert } from '@hooks/index'

const partnerTypePartner = 'Partner'

export const TypeChart = {
  organizations: 'organizations',
  accounts: 'accounts'
}

export default function useFetchReportingDashboardWidget({ setSelectedPartnerSuccess, successListPartnerDropdown, partnerIds = [] }) {
  const dispatch = useAppDispatch()
  const {
    protectionCoverage,
    getProtectionCoverage,
    seatUsage,
    getSeatUsage,
    organizations,
    getOrganizations,
    accounts,
    getAccounts,
    billing,
    getBilling
  } = useAppSelector((state) => state.reportingDashboard)
  const { payload: payloadAlert } = useHtmlPageAlert()
  const { updateSuccessAlert } = usePageAlert()

  const { partnerType, getPartnerType } = useAppSelector((state) => state.application)
  const [startDateOrganizations, setStartDateOrganizations] = useState<string>('')
  const [endDateOrganizations, setEndDateOrganizations] = useState<string>('')
  const [startDateAccounts, setStartDateAccounts] = useState<string>('')
  const [endDateAccounts, setEndDateAccounts] = useState<string>('')

  const handleDateSelected = (date1: string, date2: string, type: string = TypeChart.organizations) => {
    if (type === TypeChart.organizations) {
      setStartDateOrganizations(date1)
      setEndDateOrganizations(date2)
    } else {
      setStartDateAccounts(date1)
      setEndDateAccounts(date2)
    }
  }

  const chartDataProtection = {
    values: getProtectionCoverage
      ? 0
      : numberToPercentage(
          protectionCoverage?.protected_account_count,
          protectionCoverage?.total_account_count
        ),
    colors: ['#B9262E', '#DD252F', '#36B252'],
    colorThresholds: [50, 80, 100]
  }

  const chartDataSeatUsage = {
    values: getSeatUsage ? 0 : numberToPercentage(seatUsage?.used_seat, seatUsage?.paid_seat),
    colors: ['#DD252F', '#E7A300', '#36B252'],
    colorThresholds: [40, 80, 100]
  }

  const chartDataOrganizations = {
    values: [organizations.labels, organizations.suspended, organizations.unsubscribed, organizations.active],
    types: ['area-spline', 'area-spline', 'area-spline'],
    colors: ['#E2A208', '#BA1C31', '#0075E1']
  }

  const chartDataAccounts = {
    values: [accounts.labels, accounts.total, accounts.active, accounts.deactivated],
    colors: ['#0075E100', '#0075E1', '#8CC4F8'],
    groups: ['Active', 'Deactivated']
  }

  useEffect(() => {
    dispatch(getPartnerTypeAsync())

    return () => {
      dispatch(resetPartnerType())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getBillingAsync())

    return () => {
      dispatch(resetBilling())
    }
  }, [dispatch])

  useEffect(() => {
    if (successListPartnerDropdown) {
      dispatch(getProtectionCoverageAsync({ partner_ids: partnerIds }))
      dispatch(getSeatUsageAsync({ partner_ids: partnerIds }))
    }

    return () => {
      if (successListPartnerDropdown) {
        dispatch(resetProtectionCoverage())
        dispatch(resetSeatUsage())
      }
    }
  }, [dispatch, partnerIds])

  useEffect(() => {
    dispatch(
      getOrganizationsAsync({
        start_date: startDateOrganizations ?? endDateOrganizations,
        end_date: endDateOrganizations ?? startDateOrganizations
      })
    )

    return () => {
      dispatch(resetOrganizations())
    }
  }, [dispatch, startDateOrganizations, endDateOrganizations])

  useEffect(() => {
    if (setSelectedPartnerSuccess) {
      dispatch(
        getOrganizationsAsync({
          start_date: startDateOrganizations ?? endDateOrganizations,
          end_date: endDateOrganizations ?? startDateOrganizations
        })
      )
    }

    return () => {
      if (setSelectedPartnerSuccess) {
        dispatch(resetOrganizations())
      }
    }
  }, [setSelectedPartnerSuccess])

  useEffect(() => {
    dispatch(
      getAccountsAsync({
        start_date: startDateAccounts ?? endDateAccounts,
        end_date: endDateAccounts ?? startDateAccounts
      })
    )

    return () => {
      dispatch(resetAccounts())
    }
  }, [dispatch, startDateAccounts, endDateAccounts])

  useEffect(() => {
    if (setSelectedPartnerSuccess) {
      dispatch(
        getAccountsAsync({
          start_date: startDateAccounts ?? endDateAccounts,
          end_date: endDateAccounts ?? startDateAccounts
        })
      )
    }

    return () => {
      if (setSelectedPartnerSuccess) {
        dispatch(resetAccounts())
      }
    }
  }, [setSelectedPartnerSuccess])

  useEffect(() => {
    if (
      payloadAlert ||
      payloadAlert?.type ||
      payloadAlert?.message
    ) {
      updateSuccessAlert(payloadAlert)
    }
  }, [dispatch])

  return {
    isLoading: getProtectionCoverage && getSeatUsage && getPartnerType && getAccounts && getBilling,
    isLoadingProtectionCoverage: getProtectionCoverage,
    protectionCoverageData: protectionCoverage,
    isLoadingSeatUsage: getSeatUsage,
    seatUsageData: seatUsage,
    isLoadingOrganizations: getOrganizations,
    organizationsData: organizations,
    isLoadingAccounts: getAccounts,
    accountsData: accounts,
    billingData: billing,
    isLoadingBilling: getBilling,
    isMasterPartner: partnerType?.partner_type === partnerTypePartner,
    handleDateSelected,
    chartDataProtection,
    chartDataSeatUsage,
    chartDataOrganizations,
    chartDataAccounts
  }
}
