import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { combineReducers } from 'redux'

// Import your reducers and adjust the paths accordingly
import reportingDashboardReducer from '@features/reportingDashboard/reportingDashboardSlice'
import protectionCoverageReportReducer from '@features/protectionCoverageReport/protectionCoverageReportSlice'
import seatUsageReportReducer from '@features/seatUsageReport/seatUsageReportSlice'
import listPartnerDropdownReducer from '@features/listPartnerDropdown/listPartnerDropdownSlice'
import applicationReducer from 'bundles/features/application/applicationSlice'

const rootReducer = combineReducers({
  reportingDashboard: reportingDashboardReducer,
  protectionCoverageReport: protectionCoverageReportReducer,
  seatUsageReport: seatUsageReportReducer,
  listPartnerDropdown: listPartnerDropdownReducer,
  application: applicationReducer
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .prepend()
      // Chain prepend and concat calls
      .concat(
        // Add conditional middleware for development environment
        process.env.NODE_ENV !== 'production' ? [logger] : []
      ),
  devTools: process.env.NODE_ENV !== 'production'
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export default store
