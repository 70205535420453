import { createSlice } from '@reduxjs/toolkit'

import {
  getListPartnerDropdownAsync,
  getListPartnerDropdownExtraReducers
} from './serviceActions/getListPartnerDropdownAysnc'

import {
  setListPartnerDropdownAsync,
  setListPartnerDropdownExtraReducers
} from './serviceActions/setListPartnerDropdownAysnc'

interface listPartnerDropdownState {
  listPartnerDropdown: []
  getListPartnerDropdown: boolean
  successListPartnerDropdown: boolean
  error: null | string
  submitPartner: {
    error: null | string
    submitting: boolean
    success: boolean
  }
}

const initialState: listPartnerDropdownState = {
  listPartnerDropdown: [],
  getListPartnerDropdown: true,
  successListPartnerDropdown: false,
  error: null,
  submitPartner: {
    error: null,
    submitting: false,
    success: false
  }
}

const listPartnerDropdownSlice = createSlice({
  name: 'listPartnerDropdown',
  initialState,
  reducers: {
    resetListPartnerDropdown(state) {
      state.listPartnerDropdown = initialState.listPartnerDropdown
      state.getListPartnerDropdown = initialState.getListPartnerDropdown
      state.successListPartnerDropdown = initialState.successListPartnerDropdown
      state.error = initialState.error
    },
    resetSetListPartnerDropdown(state) {
      state.submitPartner = initialState.submitPartner
    }
  },
  extraReducers: (builder) => {
    getListPartnerDropdownExtraReducers(builder)
    setListPartnerDropdownExtraReducers(builder)
    // Add other extraReducers here if needed
  }
})

export { getListPartnerDropdownAsync, setListPartnerDropdownAsync }
export const { resetListPartnerDropdown, resetSetListPartnerDropdown } = listPartnerDropdownSlice.actions
export default listPartnerDropdownSlice.reducer
