import * as React from 'react'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, Badge } from '@mui/material'
import { stringRandom } from '@utilities/index'
import { fontActiveColor } from '@config/themes/default/variables'
import { NppTypography } from '@ui/Components'

interface NppDropdownProps {
  children?: React.ReactElement<any, any> | string | number
  withArrowIcon?: boolean
  // menuItems: (React.JSX.Element | { label: string; value: string; })[][]
  menuItems: any
  onClickItem: (
    selected: string | number | boolean | object | { label?: string | number; value?: string | number }
  ) => void
  anchorOrigin?: Record<string, string | number>
  transformOrigin?: Record<string, string | number>
  className?: string
  dropdownClassName?: string
  buttonClassName?: string
  leftIcon?: React.ReactNode
  variant?: string
  showBadge?: boolean
  headerMenu?: React.ReactNode
  disabled?: boolean
  footerMenu?: React.ReactNode | (({ handleMenuClose }: { handleMenuClose: () => void }) => React.ReactNode);
}

const optionLabel = (item: string | React.ReactNode[]): React.ReactNode => {
  let label: React.ReactNode | string = item
  let value: React.ReactNode | string | undefined
  let attributes: Record<string, any> | React.ReactNode | string = {}

  if (typeof item === 'object') [value, label, attributes] = item

  if (typeof label === 'string') {
    return <NppTypography color={(attributes as { color?: string })?.color || 'primary'}>{label}</NppTypography>
  }

  // Assuming label is ReactNode, convert it to string
  return label // Adjust this line based on your use case
}

const optionValue = (item: string | number | React.ReactNode[]): string | React.ReactNode | number | object => {
  if (typeof item === 'object') {
    return item[0]
  }

  return item
}

const menuItemAttributes = (item: string | React.ReactNode[]): Record<string, any> | any => {
  if (typeof item === 'object') {
    return item[2] || {}
  }

  return {}
}

const iconStyles = {
  color: fontActiveColor
}

const iconButtonStyles = {
  gap: '0.5rem',
  color: fontActiveColor
}

const NppDropdown: React.FC<NppDropdownProps> = ({
  withArrowIcon = true,
  menuItems,
  onClickItem,
  children,
  anchorOrigin = {},
  transformOrigin = {},
  className = '',
  dropdownClassName = '',
  buttonClassName = '',
  leftIcon,
  variant = '',
  showBadge = false,
  headerMenu,
  footerMenu,
  disabled = false
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  const menuId = `NppDropdown--${stringRandom(7)}`

  const onDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuClick = (selected?: string | number | object | React.ReactNode) => {
    if (selected) onClickItem(selected)
  }

  const handleMenuClickAndClose = (selected?: string | number | object | React.ReactNode) => {
    setAnchorEl(null)
    if (selected) onClickItem(selected)
  }

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const renderLeftIcon = () => {
    if (!leftIcon) return null
    if (showBadge) {
      return (
        <Badge
          className="NppButton__badge"
          color="primary"
          variant="dot"
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
          {leftIcon}
        </Badge>
      )
    }
    return leftIcon
  }

  return (
    <Box className={`NppDropdown__wrapper ${className} ${disabled ? 'NppDropdown__disable-button' : ''}`}>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <IconButton
          size="large"
          edge="end"
          aria-label={menuId}
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={disabled ? () => {} : onDropdownOpen}
          color="inherit"
          sx={iconButtonStyles}
          className={`IconButton__default ${buttonClassName}`}
          disableRipple>
          {renderLeftIcon()}
          {children}
          {withArrowIcon && <i className="solid-arrow-down" />}
        </IconButton>
      </Box>

      <Menu
        className={`NppDropdown__menu ${dropdownClassName}`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
          ...anchorOrigin
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
          ...transformOrigin
        }}
        open={isOpen}
        onClose={handleMenuClose}
        onKeyDown={(e) => e.stopPropagation()}>
        {headerMenu}

        <div className='NppDropdown__menu-item'>
          {menuItems.length > 0 &&
            menuItems.map((item, index) => {
              let attributes: { divider?: boolean; component?: string; to?: string; onClick?: () => void } = {}
              const mItemAttributes = menuItemAttributes(item)

              if (mItemAttributes) {
                const { divider, component, to, onClick } = mItemAttributes

                if (divider) attributes.divider = divider
                if (component) attributes.component = component
                if (to) attributes.to = to
                if (onClick) attributes.onClick = onClick
              }

              /* eslint-disable react/no-array-index-key */
              return (
                <MenuItem
                  key={index}
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation()
                    variant === 'checkbox'
                      ? handleMenuClick(optionValue(item))
                      : handleMenuClickAndClose(optionValue(item))
                  }}
                  {...attributes}>
                  {optionLabel(item)}
                </MenuItem>
              )
            })}
        </div>
        {typeof footerMenu === 'function' ? footerMenu({ handleMenuClose }) : footerMenu}
      </Menu>
    </Box>
  )
}

export default NppDropdown
