// import BaseSetting from "@config/BaseSetting";
import Routes from './Routes'

/**
 * Contains web api urls
 * */

class WebClientRoutes extends Routes {
  rootPath(params = {}, format = '') {
    return this.createUrl('/', params, format)
  }
  reportingDashboardPath(params = {}, format = '') {
    return this.createUrl('/dashboard', params, format)
  }
  protectionCoverageReportPath(params = {}, format = '') {
    return this.createUrl('/dashboard/protection-coverage-report', params, format)
  }
  seatUsageReportPath(params = {}, format = '') {
    return this.createUrl('/dashboard/seat-usage-report', params, format)
  }
}

const ClientRoutes = new WebClientRoutes()

export const rootPath = ClientRoutes.rootPath()
export const reportingDashboardPath = ClientRoutes.reportingDashboardPath()
export const protectionCoverageReportPath = ClientRoutes.protectionCoverageReportPath()
export const seatUsageReportPath = ClientRoutes.seatUsageReportPath()

export default ClientRoutes
