import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ReportingDashboardPage, ProtectionCoverageReport, SeatUsageReport } from '@pages/index'
import {
  rootPath,
  reportingDashboardPath,
  protectionCoverageReportPath,
  seatUsageReportPath
} from 'bundles/config/Routes/WebClientRoutes'

export default (
  <Routes>
    <Route path={rootPath} element={<ReportingDashboardPage />} />
    <Route path={reportingDashboardPath} element={<ReportingDashboardPage />} />
    <Route path={protectionCoverageReportPath} element={<ProtectionCoverageReport />} />
    <Route path={seatUsageReportPath} element={<SeatUsageReport />} />
  </Routes>
)
