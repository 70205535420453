import * as React from 'react'
import * as c3 from 'c3'

interface NppChartC3Props {
  data: {
    values: number[] | number | (string | number)[][]
    labels?: number[] | string[]
    colors?: string[]
    colorThresholds?: number[]
    types?: string[],
    groups?: string[]
  }
  type?: 'gauge' | 'multiple' | 'stackedBar'
  title?: string | number
  showValue?: boolean
}

interface ChartSeriesType {
  [key: string]: string
}

interface ChartSeriesColors {
  [key: string]: string
}

type YAxisCalculationResult = {
  maxYValue: number
  yValues: number[]
}

const calculateYAxisValues = (
  columns: (string | number)[][],
  maxTicks: number = 13
): YAxisCalculationResult => {
  let currentMax = 0 // Initialize currentMax to 0

  // Process each row, skipping any row where the first element is 'x'
  columns.forEach((row) => {
    if (row[0] !== 'x') {
      // Skip the row if its first element is 'x'
      row.slice(1).forEach((value) => {
        // Skip the first entry of each row, which is a label
        if (typeof value === 'number' && value > currentMax) {
          currentMax = value
        }
      })
    }
  })

  // Calculate step size
  let tempStep = currentMax / maxTicks
  let mag = Math.floor(Math.log10(tempStep))
  let magPow = Math.pow(10, mag)
  let magMsd = Math.floor(tempStep / magPow + 0.5)
  let stepSize = Math.ceil(magMsd * magPow)

  // Calculate lower and upper bounds
  let upperBound = stepSize * Math.ceil(currentMax / stepSize)
  let yLabels: number[] = []
  for (let currentBound = 0; currentBound <= upperBound; currentBound += stepSize) {
    yLabels.push(Math.ceil(currentBound))
  }

  return {
    maxYValue: currentMax,
    yValues: yLabels
  }
}

const NppChartC3 = ({ data, type, title, showValue }: NppChartC3Props) => {
  const { useEffect, useRef } = React
  const chartId = `chart-${Math.floor(Math.random() * 1000)}`

  //Validate data array of array
  const isArrayOfArrays = (values: any): values is (string | number)[][] => {
    return Array.isArray(values) && values.every(Array.isArray)
  }
  // Filter out 'x' from chartTypes mapping
  const seriesNames = isArrayOfArrays(data?.values)
    ? data?.values?.filter((column) => column[0] !== 'x').map((column) => column[0] as string)
    : []

  // Generate types object, ensuring we exclude 'x' and align with provided chartTypes
  const types: ChartSeriesType = seriesNames.reduce((acc, name, index) => {
    const type = data?.types?.[index] || 'area-spline'
    acc[name] = type
    return acc
  }, {})

  const colors: ChartSeriesColors = seriesNames.reduce((acc, name, index) => {
    const color = data?.colors?.[index] || 'area-spline'
    acc[name] = color
    return acc
  }, {})

  const { maxYValue, yValues } = isArrayOfArrays(data?.values)
    ? calculateYAxisValues(data.values)
    : { maxYValue: 0, yValues: [] }

  const ChartTypeGauge = {
    bindto: `#${chartId}`,
    data: {
      columns: [[title, data.values]],
      type: 'gauge',
      onclick: (d, i) => {},
      onmouseover: (d, i) => {},
      onmouseout: (d, i) => {}
    },
    gauge: {
      // Customize gauge options here
      label: {
        show: false, // to turn off the min/max labels.
        format: function (value, ratio) {
          return value.toFixed(2) + '%' // Custom format
        }
      },
      width: 40
    },
    color: {
      pattern: data?.colors,
      threshold: {
        values: data?.colorThresholds
      }
    },
    size: {
      width: 300
    },
    legend: {
      item: {
        onclick: () => {} // Override the default onclick behavior
      }
    }
  }

  const ChartTypeMultiple = {
    bindto: `#${chartId}`,
    size: {
      height: 300
    },
    data: {
      columns: data?.values ?? [],
      x: 'x',
      types,
      groups: Array.isArray(data?.values)
        ? data?.values
            ?.filter((column) => Array.isArray(column) && column.length > 0 && column[0] !== 'x')
            .map((column) => column)
        : [],
      colors
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      mode: 'label'
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [
        {
          display: false,
          scaleLabel: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true
          }
        }
      ]
    },
    point: {
      r: 0,
      focus: {
        expand: {
          r: 6
        }
      }
    },
    axis: {
      y: {
        min: 0,
        max: maxYValue,
        tick: {
          values: yValues
        }
      },
      x: {
        type: 'timeseries',
        tick: {
          format: '%d %b'
        }
      }
    },
    grid: {
      y: {
        show: true
      },
      x: {
        show: false
      }
    }
  }

  const ChartTypeStackedBar = {
    bindto: `#${chartId}`,
    size: {
      height: 300
    },
    data: {
      columns: data?.values ?? [],
      x: 'x',
      type: 'bar',
      groups: [data?.groups ?? []],
      colors
    },
    legend: {
      show: true
    },
    point: {
      r: 0,
      focus: {
        expand: {
          r: 6
        }
      }
    },
    axis: {
      y: {
        min: 0
      },
      x: {
        type: 'timeseries',
        tick: {
          format: '%d %b'
        }
      }
    },
    grid: {
      y: {
        show: true
      },
      x: {
        show: false
      }
    },
    tooltip: {}
  }

  let ChartType = {
    gauge: ChartTypeGauge,
    multiple: ChartTypeMultiple,
    stackedBar: ChartTypeStackedBar
  }

  useEffect(() => {
    // Create chart when component mounts
    if (chartId) {
      c3.generate(ChartType[type])
    }
  }, [data])

  return (
    <div id={chartId} className={`ChartC3__wrapper ChartC3__type-${type}`} style={{ maxHeight: '300px' }} />
  )
}

NppChartC3.defaultProps = {
  type: 'gauge',
  label: '',
  showValue: false
}

export default NppChartC3
