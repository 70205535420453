/**
 * Basic Setting Variables Define
 */

export const constant = {
  PARTNER_FILTER_STORAGE: 'PARTNER_FILTER_STORAGE'
}
const BaseSetting = {
  backendDateTimeFormat: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  paginationPerPageNumbers: ['10', '25', '50', '100'],
  paginationPerPageDefault: '10',
  minimumShowPagination: '10',
  allowTypeRequestLength: 1,
  searchTypeDelay: 500,
  csrfToken: document.querySelector<HTMLMetaElement>('[name="csrf-token"]')?.content ?? null,
}

export default BaseSetting
