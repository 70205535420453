import * as React from 'react'
import i18n from 'i18n'
import { NppCard, NppTypography, NppButton } from '@ui/Components'

interface BillingCardSectionProps {
  billingData: {
    currency: string
    total_invoices: number
    outstanding_invoice_count: number
    outstanding_invoice_date: string
  }
  isLoading: boolean
  isMasterPartner: boolean
}

const BillingCardSection = ({ billingData, isLoading, isMasterPartner }: BillingCardSectionProps) => {
  const { t } = i18n

  if (!isMasterPartner) return null

  return (
    <NppCard
      alignButton="center"
      className={`ReportingDashboardBilling__card${isLoading ? '-loading' : ''}`}
      loading={isLoading}>
      <div className="ReportingDashboardBilling__card-left">
        <i className="line-bill-alt" />
        <div className="ReportingDashboardBilling__card-description">
          <NppTypography variant="subtitle1">
            {t('reporting_dashboard.index.billing_card.header')}
          </NppTypography>
          <NppTypography variant="h3">{`${billingData?.currency ?? '$'} ${
            billingData?.total_invoices ?? 0
          }`}</NppTypography>
          <NppTypography variant="caption">
            {billingData?.outstanding_invoice_count > 0
              ? t('reporting_dashboard.index.billing_card.description', {
                  outstanding: `${billingData?.currency ?? '$'}${billingData?.outstanding_invoice_count ?? 0}`,
                  date: billingData?.outstanding_invoice_date ?? ''
                })
              : t('reporting_dashboard.index.billing_card.description_no_outstanding')}
          </NppTypography>
        </div>
      </div>
      <NppButton variant="outlined" href="/billing">
        {t('reporting_dashboard.index.billing_card.billing_details_button')}
      </NppButton>
    </NppCard>
  )
}

export default BillingCardSection
